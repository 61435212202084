const submitErrorMsg = `There was an error submitting the feedback, please try again later, sorry for the inconvenience`;

export default function useSubmitFeedbackForm({
  showError,
  associateUploadedFileWithFeedbackId,
}) {
  const runtimeConfig = useRuntimeConfig();
  const isSubmitting = ref(false);

  const submitForm = async (formAnswers) => {
    try {
      // Disable submit button so there's no double submission
      isSubmitting.value = true;
      const response = await fetch(
        `${runtimeConfig.public.API_BASE_URL}/feedback`,
        {
          method: `post`,
          body: JSON.stringify(formAnswers),
          headers: {
            'Content-Type': `application/json`,
          },
        },
      );
      const responseBody = await response.json();
      if (response.status !== 201) {
        isSubmitting.value = false;
        if (response.status === 422) {
          showError(submitErrorMsg);
          console.error(responseBody.errors);
          return;
        }
        showError(submitErrorMsg);
        return;
      }
      associateUploadedFileWithFeedbackId(responseBody.id);

      isSubmitting.value = false;
      // To go to the next step
      return true;
    } catch (error) {
      showError(submitErrorMsg);
      isSubmitting.value = false;
    }
  };

  return {
    submitForm,
    isSubmitting,
  };
}
