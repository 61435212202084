export const FEEDBACK_TYPES = {
  bug: {
    title: `Bug`,
    value: `bug`,
    feedbackDescription: `1. Actual behavior (what is the bug): (fill in)
2. Expected behavior (what you'd expect): (fill in)
3. Steps to reproduce (how to see the bug): (fill in)`,
  },
  newFeature: {
    title: `New Feature`,
    value: `newFeature`,
    feedbackDescription: `1. What is the necessity: (fill in)
2. Would you pay for it and how much: (fill in)
3. What is the feature: (fill in)`,
  },
  improvement: {
    title: `Improvement`,
    value: `improvement`,
    feedbackDescription: `1. Where the improvement should occur: (fill in)
2. Please describe the improvement: (fill in)`,
  },
  growth: {
    title: `Growth`,
    value: `growth`,
    feedbackDescription: ``,
  },
  accessibility: {
    title: `Accessibility`,
    value: `accessibility`,
  },
  legal: {
    title: `Legal`,
    value: `legal`,
  },
  seo: {
    title: `SEO optimization`,
    value: `seo`,
  },
  security: {
    title: `Cyber Security`,
    value: `security`,
  },
  soc2: {
    title: `SOC 2`,
    value: `soc2`,
  },
};

export const APP_TYPES = {
  web: {
    title: `Web (accessed by a browser, e.g Chrome)`,
    value: `web`,
  },
  android: {
    title: `Android`,
    value: `android`,
  },
  ios: {
    title: `iOS`,
    value: `ios`,
  },
  desktop: {
    title: `Desktop app (Windows)`,
    value: `desktop`,
  },
};

export const feedbackFormAnswers = (
  feedbackUrl = ``,
  appName = ``,
  appType = APP_TYPES.web.value,
  appUrl = '',
) => {
  return {
    feedbackUrl,
    appId: '',
    feedbackType: FEEDBACK_TYPES.bug.value,
    user: {
      email: ``,
      firstName: ``,
      lastName: ``,
      isSubscribedToNewsletter: true,
    },
    acceptTermsOfService: true,
    feedbackWorth: null,
    description: FEEDBACK_TYPES.bug.feedbackDescription,
    isAnonymous: false,
    app: {
      storeId: null,
      type: appType,
      url: appUrl,
      name: appName,
    },
    device: {
      os: undefined,
      osVersion: undefined,
      browser: undefined,
      browserVersion: undefined,
      screenSize: undefined,
      browserSize: undefined,
    },
  };
};

export const supportedFileTypes = [
  `image/jpg`,
  `image/jpeg`,
  `image/png`,
  `image/avif`,
  `image/webp`,
];

export const MAX_FILE_SIZE_MB = 5;
export const MAX_FILES_ALLOWED = 5;
