<template>
  <v-stepper-item
    :title="props.title"
    :value="props.itemStep"
    :complete="props.currentStep > props.itemStep"
    class="px-1"
  >
    <template #icon>
      <v-icon :icon="determineIcon" size="20" color="white" />
    </template>
    <template #title>
      <h4 class="stepper-item-title">{{ props.title }}</h4>
    </template>
  </v-stepper-item>
</template>

<script setup>
import { mdiCheck, mdiLeadPencil, mdiClockOutline } from '@mdi/js';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  itemStep: {
    type: Number,
    required: true,
  },
  currentStep: {
    type: Number,
    required: true,
  },
});

const determineIcon = computed(() => {
  if (props.itemStep < props.currentStep) {
    // Already completed the step
    return mdiCheck;
  }
  if (props.itemStep === props.currentStep) {
    // Current step
    return mdiLeadPencil;
  }
  return mdiClockOutline;
});
</script>

<style>
.selected {
  color: rgb(var(--v-theme-info-darken-1));
  font-weight: bold;
  opacity: 1 !important;
}

.stepper-item-title {
  font-weight: 700;
  font-size: 14px;
  color: rgb(var(--v-theme-info-darken-1));
}

.v-stepper-item__avatar.v-avatar {
  background: rgb(var(--v-theme-info-darken-1)) !important;
}
.v-stepper-item.v-stepper-item--complete {
  color: rgb(var(--v-theme-primary-darken-2));
  opacity: 1 !important;

  .v-stepper-item__avatar.v-avatar {
    background: rgb(var(--v-theme-primary-darken-2)) !important;
  }

  .stepper-item-title {
    color: rgb(var(--v-theme-primary-darken-2));
  }
}

.v-avatar {
  /* So the outer ellipse can be seen */
  overflow: unset !important;
}

.v-stepper-item.selected {
  /* Ellips for the selected item */
  .v-avatar.v-stepper-item__avatar::before {
    border: 2px solid rgb(var(--v-theme-info-darken-1));
    border-radius: 2em;
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    width: 32px;
    height: 32px;
  }
}
</style>
