export default function useFileUpload({ fileRules, showError }) {
  const runtimeConfig = useRuntimeConfig();

  const filesToUpload = ref();
  const isUploadingFile = ref(false);
  const uploadedFilesIds = ref([]);

  const deleteUploadedFiles = async () => {
    await deleteFiles(uploadedFilesIds.value);
    uploadedFilesIds.value = [];
  };

  const deleteFiles = async (filesIds) => {
    if (!!filesIds && Array.isArray(filesIds) && Boolean(filesIds.length)) {
      const filesIdsToQueryParam = filesIds.join(`,`);
      await fetch(
        `${runtimeConfig.public.API_BASE_URL}/file/?ids=${filesIdsToQueryParam}`,
        {
          method: `DELETE`,
        },
      );
    }
  };

  watch(
    () => filesToUpload.value,
    (newFile, oldFile) => {
      if (!Array.isArray(filesToUpload.value) || !filesToUpload.value.length) {
        // File was removed, deleting it
        deleteUploadedFiles();
        return;
      }
      if (newFile?.[0]?.name !== oldFile?.[0]?.name) {
        // File is changed, deleting old one
        deleteUploadedFiles();
      }
      uploadFiles(filesToUpload.value);
    },
  );

  const uploadFiles = async (files) => {
    try {
      const validationsDidntPass = fileRules.some(
        (validationFn) => validationFn(filesToUpload.value) !== true,
      );
      if (validationsDidntPass) {
        return;
      }

      const formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        // This is an easy way to append an array otherwise the elements of it appear as strings
        formData.append(`files[]`, files[i]);
      }

      isUploadingFile.value = true;
      const response = await fetch(
        `${runtimeConfig.public.API_BASE_URL}/file`,
        {
          method: `POST`,
          body: formData,
        },
      );

      if (response.status !== 201) {
        throw new Error(`Failed to upload files`);
      }
      const responseBody = await response.json();
      if (
        responseBody &&
        responseBody.files &&
        Array.isArray(responseBody.files)
      ) {
        uploadedFilesIds.value = responseBody.files.map((file) => file.id);
      }
    } catch (error) {
      console.error(`Error uploading files:`, error);
      showError(
        `There was an error uploading the files, please try again later, sorry for the inconvenience.`,
      );
    } finally {
      isUploadingFile.value = false;
    }
  };

  const associateFileWithFeedbackId = async (uploadedFilesIds, feedbackId) => {
    if (
      !!feedbackId &&
      !!uploadedFilesIds &&
      Boolean(uploadedFilesIds.length)
    ) {
      await fetch(
        `${runtimeConfig.public.API_BASE_URL}/file/associate-to-feedback`,
        {
          method: `PUT`,
          body: JSON.stringify({
            feedbackId,
            filesIds: uploadedFilesIds,
          }),
          headers: {
            'Content-Type': `application/json`,
          },
        },
      );
    }
  };

  const associateUploadedFileWithFeedbackId = (feedbackId) => {
    associateFileWithFeedbackId(uploadedFilesIds.value, feedbackId);
  };

  return {
    deleteFiles,
    deleteUploadedFiles,
    uploadFiles,
    isUploadingFile,
    filesToUpload,
    uploadedFilesIds,
    associateFileWithFeedbackId,
    associateUploadedFileWithFeedbackId,
  };
}
