import { ref, onMounted, onBeforeUnmount } from 'vue';

export function useSpeechRecognition() {
  const isRecording = ref(false);
  const resultText = ref(null);

  let recognition = null;

  onMounted(() => {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.lang = 'en-US';

      recognition.onstart = () => {
        isRecording.value = true;
        console.log('Recording started');
      };

      recognition.onresult = (event) => {
        let result = '';

        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            result += event.results[i][0].transcript + ' ';
          } else {
            result += event.results[i][0].transcript;
          }
        }

        if (result.toLowerCase().includes('stop recording')) {
          resultText.value = result.replace(/stop recording/gi, '');
          stopRecording();
          return;
        }

        resultText.value = result;
      };

      recognition.onerror = (event) => {
        isRecording.value = false;
        console.error('Speech recognition error:', event.error);
      };

      recognition.onend = () => {
        isRecording.value = false;
        console.log('Speech recognition ended');
      };
    } else {
      console.error('Speech recognition not supported');
    }
  });

  onBeforeUnmount(() => {
    if (recognition) {
      recognition.stop();
    }
  });

  function startRecording() {
    if (recognition) {
      resultText.value = '';
      recognition.start();
    }
  }

  function stopRecording() {
    if (recognition) {
      recognition.stop();
    }
  }

  return {
    isRecording,
    resultText,
    startRecording,
    stopRecording,
  };
}
